// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import {Howl, Howler} from 'howler';

document.addEventListener("turbolinks:load", () => {


  // MUSIC PLAY
  var playing = false
  var initiated = false
  const nowplaying = document.querySelector('#nowplaying');
  const progress = document.querySelector('#progress')

  // Selecting all albums that appear on page
  var albumImages = document.querySelectorAll('.album .image')
  var albumImagesAudioMP3 = document.querySelectorAll('.album .audio')
  var albumTitle = document.querySelectorAll('.album .audioTitle')

  // Define first song variable
  var sound = new Howl({
    src: [albumImagesAudioMP3[0].innerHTML],
    html5: true
  });

  // For each album artwork, add a click event listener
  for (i = 0; i < albumImages.length; i++) {
    albumImages[i].addEventListener("mousedown", playAudio(i));
  }

  // Called function on play click
  function playAudio(i) {
    return function() {
      // Pauses current track
      sound.pause();

      // Re-defines current song playing
    	sound = new Howl({

    		// Insert files to play here:
    		src: [albumImagesAudioMP3[i].innerHTML],
        html5: true,
    		onend: function() {
    			showPause()
    		}
    	});

      // Play newly defined track
    	sound.play();

    	// Title of track by Artist
    	nowplaying.innerHTML = albumTitle[i].innerHTML
    	playing = true
    	check()
    }
  }

  // Controls
  const playBtn = document.querySelector('#playBtn');
  const pauseBtn = document.querySelector('#pauseBtn');
  const nextBtn = document.querySelector('#nextBtn');
  const prevBtn = document.querySelector('#prevBtn');

  pauseBtn.addEventListener("click", function() {
  	playBtn.style.display = 'block';
      pauseBtn.style.display = 'none';
  	sound.pause();
  })

  // Will default play first track
  playBtn.addEventListener("click", function() {
  	playBtn.style.display = 'none';
      pauseBtn.style.display = 'block';
  	sound.play();

  	if (initiated == false) {
  		sound.pause();
  		sound = new Howl({
  			src: [albumImagesAudioMP3[0].innerHTML],
        html5: true,
  			onend: function() {
  				showPause()
  			}
  		});
  		sound.play();
  		nowplaying.innerHTML = albumTitle[0].innerHTML
  		playing = true
  		check()
  	}
  })

  nextBtn.addEventListener("click", function() {
  	var progressSeek = sound.seek()
  	progressSeek = progressSeek + 15
  	sound.seek(progressSeek)
  })

  prevBtn.addEventListener("click", function() {
  	var progressSeek = sound.seek()
  	progressSeek = progressSeek - 15
  	sound.seek(progressSeek)
  })

  // Runs a check to see if there is something playing, calls progress update if there is
  function check() {
  	initiated = true
  	if (playing == true) {
  		playBtn.style.display = 'none';
      pauseBtn.style.display = 'block';
  		progressUpdate()
  	} else {
  		playBtn.style.display = 'block';
      	pauseBtn.style.display = 'none';
  	}
  }

  // Progress update that refreshes every 200 milliseconds
  function progressUpdate() {
  	setTimeout(function(){
  		var progressSeek = sound.seek()
  		progress.style.width = (((progressSeek / sound.duration()) * 100) || 0) + '%';
  		progressUpdate()
  	}, 200);
  }

  // Function that runs at the end of a song to reset the play button
  function showPause() {
  	playBtn.style.display = 'block';
      pauseBtn.style.display = 'none';
  }



  // KEY CLICK TO OPEN
  const keyWrapper = document.querySelector('.keyfull')
  const svgWrapper = document.querySelector('.svgwrapper svg')
  const svgWrapperMobile = document.querySelector('#mobileTab svg')
  var open = true
  var mobileOpen = false

  svgWrapper.addEventListener("click", function() {

  	if (open == false) {
  		keyWrapper.style.bottom = '0px'
  		open = true
  	}

  	else {
  		if ($(window).width() > 1400) {
  			keyWrapper.style.bottom = '-97px'
  		} else if ($(window).width() > 1000) {
  			keyWrapper.style.bottom = '-113px'
  		} else {
  			keyWrapper.style.bottom = '-113px'
  		}

  		open = false
  	}
  });

  svgWrapperMobile.addEventListener("click", function() {

  	if (mobileOpen == false) {
  		keyWrapper.style.bottom = '0px'
  		mobileOpen = true
  	}

  	else {
  		if ($(window).width() > 1400) {
  			keyWrapper.style.bottom = '0px'
  		} else if ($(window).width() > 850) {
  			keyWrapper.style.bottom = '-150px'
  		} else {
  			keyWrapper.style.bottom = '-155px'
  		}

  		mobileOpen = false
  	}
  });



  // LOADING PAGE
  const loadingPage = document.querySelector('.loadingpage')
  const searchBar = document.querySelector('.searchbar')

  const heading = document.querySelector('header h1')
  //const intro = document.querySelector('#intro')

  var image = document.querySelectorAll('.image')
  var number = document.querySelectorAll('.number')
  var i

  window.addEventListener("load", function () {

    // Reset page positioning
    window.scrollTo(0, 0);
    console.log('ScrollToTop')

  	setTimeout(function(){

  		loadingPage.style.opacity = '0'
  		loadingPage.style.zIndex = '-50'
  		searchBar.style.width = '100%'

  		for (i = 0; i < image.length; i++) {
  			image[i].style.paddingTop = '99%'
  			number[i].innerHTML = ('000' + (i + 1)).substr(-3)
  		}

  		keyWrapper.style.transition = '1s'

      // Checking what state to load the key in
  		if ($(window).width() < 851) {
  			keyWrapper.style.bottom = '-155px'
  		} else if ($(window).width() < 1400) {
        if ($(window).height() < 950) {
          keyWrapper.style.bottom = '-113px'
          open = false
        } else {
          keyWrapper.style.bottom = '0px'
        }
      } else {
        if ($(window).height() < 800) {
          keyWrapper.style.bottom = '-97px'
          open = false
        } else {
          keyWrapper.style.bottom = '0px'
        }
  		}


  		setTimeout(function(){
  			keyWrapper.style.transition = '0.15s'
  			heading.style.opacity = '1'
  			//intro.style.opacity = '1'
  		}, 200);

  	}, 500);

  });



  // SEARCH BAR
  const searchInput = document.forms['searchInput'].querySelector('input');
  const list = document.querySelectorAll('.name h2');
  const album = document.querySelectorAll('.album')
  var i = 0;

  searchInput.addEventListener('keyup', function(e){

  	const term = e.target.value.toLowerCase();

  	for (i = 0; i < list.length; i++) {

  		var title = list[i].textContent

  		if(title.toLowerCase().indexOf(term) != -1) {
  			album[i].style.display = 'block'
  		}

  		else {
  			album[i].style.display = 'none'
  		}
  	}

  })







  // FILTER
  $(document).ready(function() {

  	// PUll - PUSH ANIMATION
  	var numbertime = document.querySelectorAll('.numbertime')
  	var description = document.querySelectorAll('.description')
  	var publisher = document.querySelectorAll('.publisher')

  	function refreshfilter() {

  		// for (i = 0; i < image.length; i++) {
  		// 	image[i].style.paddingTop = '0%'
  		// 	image[i].style.opacity = '0'
  		// 	numbertime[i].style.opacity = '0'
  		// 	description[i].style.opacity = '0'
  		// 	publisher[i].style.opacity = '0'
  		// }
      //
  		// setTimeout(function(){
  		// 	const album = document.querySelectorAll('.album')
      //
  		// 	for (i = 0; i < image.length; i++) {
  		// 		image[i].style.paddingTop = '99%'
  		// 		image[i].style.opacity = '1'
  		// 		numbertime[i].style.opacity = '1'
  		// 		description[i].style.opacity = '1'
  		// 		publisher[i].style.opacity = '1'
  		// 	}
  		// }, 0);
  	}


  	var filter = false


    $("footer .writer").click(function() {

  		refreshfilter()

  		setTimeout(function(){
  			$(".album").addClass("hide");
  			$(".album").removeClass("show");

  			$(".album.writer").addClass("show");
  			$(".album.writer").removeClass("hide");

  			$(".filter").css('text-decoration', 'none');
  			$("footer .writer").css('text-decoration', 'underline');
  		}, 0);

  		filter = true

    });


  	$("footer .producer").click(function() {

  		refreshfilter()

  		setTimeout(function(){
  			$(".album").addClass("hide");
  			$(".album").removeClass("show");

  			$(".album.producer").addClass("show");
  			$(".album.producer").removeClass("hide");

  			$(".filter").css('text-decoration', 'none');
  			$("footer .producer").css('text-decoration', 'underline');
  		}, 0);

  		filter = true

    });


  	$("footer .performer").click(function() {

  		refreshfilter()

  		setTimeout(function(){
  			$(".album").addClass("hide");
  			$(".album").removeClass("show");

  			$(".album.performer").addClass("show");
  			$(".album.performer").removeClass("hide");

  			$(".filter").css('text-decoration', 'none');
  			$("footer .performer").css('text-decoration', 'underline');
  		}, 0);

  		filter = true

      });


  	$("footer .engineer").click(function() {

  		refreshfilter()

  		setTimeout(function(){
  			$(".album").addClass("hide");
  			$(".album").removeClass("show");

  			$(".album.engineer").addClass("show");
  			$(".album.engineer").removeClass("hide");

  			$(".filter").css('text-decoration', 'none');
  			$("footer .engineer").css('text-decoration', 'underline');
  		}, 0);

  		filter = true

      });


  	$("footer .additionalproduction").click(function() {

  		refreshfilter()

  		setTimeout(function(){
  			$(".album").addClass("hide");
  			$(".album").removeClass("show");

  			$(".album.additionalproduction").addClass("show");
  			$(".album.additionalproduction").removeClass("hide");

  			$(".filter").css('text-decoration', 'none');
  			$("footer .additionalproduction").css('text-decoration', 'underline');
  		}, 0);

  		filter = true

      });


  	$("footer .remix").click(function() {

  		refreshfilter()

  		setTimeout(function(){
  			$(".album").addClass("hide");
  			$(".album").removeClass("show");

  			$(".album.remix").addClass("show");
  			$(".album.remix").removeClass("hide");

  			$(".filter").css('text-decoration', 'none');
  			$("footer .remix").css('text-decoration', 'underline');
  		}, 0);

  		filter = true

    });


  	$("footer .ariagold").click(function() {

  		refreshfilter()

  		setTimeout(function(){
  			$(".album").addClass("hide");
  			$(".album").removeClass("show");

  			$(".album.ariagold").addClass("show");
  			$(".album.ariagold").removeClass("hide");

  			$(".filter").css('text-decoration', 'none');
  			$("footer .ariagold").css('text-decoration', 'underline');
  		}, 0);

  		filter = true

    });


  	$("footer .ariaplatinum").click(function() {

  		refreshfilter()

  		setTimeout(function(){
  			$(".album").addClass("hide");
  			$(".album").removeClass("show");

  			$(".album.ariaplatinum").addClass("show");
  			$(".album.ariaplatinum").removeClass("hide");

  			$(".filter").css('text-decoration', 'none');
  			$("footer .ariaplatinum").css('text-decoration', 'underline');
  		}, 0);

  		filter = true

    });


  	$("footer .triplej").click(function() {

  		refreshfilter()

  		setTimeout(function(){
  			$(".album").addClass("hide");
  			$(".album").removeClass("show");

  			$(".album.triplej").addClass("show");
  			$(".album.triplej").removeClass("hide");

  			$(".filter").css('text-decoration', 'none');
  			$("footer .triplej").css('text-decoration', 'underline');
  		}, 0);

  		filter = true

    });


  	$(".svgwrapper").click(function() {

      ResetFilter()

    });


    $("#gabStrum").click(function() {

      ResetFilter()

    });


  	$("footer .filter").click(function() {

  		$('#keytext').text("Reset Filter");
  		$('#keytext').attr('transform', 'translate(57 14.35)');

  	});

    function ResetFilter() {
      if (filter == true) {

        refreshfilter()

        setTimeout(function(){
          $(".album").addClass("show");
          $(".album").removeClass("hide");

          $('#keytext').text("Key");
          $('#keytext').attr('transform', 'translate(75.62 14.35)');

          $(".filter").css('text-decoration', 'none');
        }, 0);

        filter = false

      } else {

        $(".album").addClass("show");
        $(".album").removeClass("hide");

        $('#keytext').text("Key");
        $('#keytext').attr('transform', 'translate(75.62 14.35)');

        $(".filter").css('text-decoration', 'none');

      }
    }

  });

  // Smooth Scroll on page
  $(document).ready(function(){
	  // Add smooth scrolling to all links
	  $("a").on('click', function(event) {

		// Make sure this.hash has a value before overriding default behavior
		if (this.hash !== "") {
		  // Prevent default anchor click behavior
		  event.preventDefault();

		  // Store hash
		  var hash = this.hash;

		  // Using jQuery's animate() method to add smooth page scroll
		  // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
		  $('html, body').animate({
			scrollTop: $(hash).offset().top
		  }, 800, function(){

			// Add hash (#) to URL when done scrolling (default click behavior)
			window.location.hash = hash;
		  });
		} // End if
	  });
	});

  var maxscroll = $(".overflowing").width();

  // for stuff that scrolls left on hover
  $(".overflowing").mouseover(function() {
      var speed = maxscroll * 15;

      $( this ).css('text-overflow', 'clip');
      $( this ).animate( {
          scrollLeft: speed
      }, 7500, 'swing' );//set timer for slower one
  });

  $( ".overflowing" ).mouseout(function() {
      $( this ).stop();
      $( this ).css('text-overflow', 'ellipsis');
      $( this ).animate( {
          scrollLeft: 0
      }, 'fast');
  });

})
